/* stylelint-disable selector-class-pattern */
:root {
    [data-theme='remax'] {
        --article-background: #c5d5e8;
        --body-primary-color: #0d349d;
        --body-heading-color: #0b295e;
        --body-text-color: var(--body-primary-color);
    }
}

:global {
    :local(.container) {
        --color-white-text: #e1e8f4;

        .block-heading {
            h3 {
                text-transform: uppercase;
            }
        }

        .block-text {
            position: relative;

            .bubble {
                position: relative;
                padding: 1.2em 0.8em 1em;
                border-radius: 10px;
                background:
                    linear-gradient(var(--bg-line-color) 1px, transparent 1px),
                    linear-gradient(90deg, var(--bg-line-color) 1px, transparent 1px);
                background-color: var(--body-primary-color);
                background-position: 13.4px -2px;
                background-size: 17px 17px;
                color: var(--color-white-text);
                font-family: var(--font-agrandir);
                font-size: 17px;
                font-weight: 500;
                letter-spacing: 0.03em;

                --bg-line-color: rgb(225 232 244 / 0.25);

                /* icons */
                &::after {
                    position: absolute;
                    display: block;
                    height: auto;
                    transform-origin: center;
                }

                &[data-icon='salt-shaker'] {
                    padding-right: 25px;
                    margin-bottom: 65px;

                    &::after {
                        right: 10px;
                        bottom: -45%;
                        width: 60px;
                        content: url('../../assets/img/animations/remax/salt-shaker.svg');

                        & :local {
                            animation: salt-shake 1s ease-in-out 0s infinite alternate;
                        }
                    }
                }

                &[data-icon='red-star'] {
                    padding-right: 25px;
                    margin-bottom: 70px;

                    &::after {
                        right: 0;
                        bottom: -70%;
                        width: 110px;
                        content: url('../../assets/img/animations/remax/red-star.svg');

                        & :local {
                            animation: star-spin 25s linear infinite alternate;
                        }
                    }
                }
            }

            /* arrow */
            &:has(.bubble) {
                --arrow-size: 25px;

                margin-top: calc(var(--arrow-size) + 10px);

                &::before {
                    position: absolute;
                    top: calc(0px - var(--arrow-size));
                    right: 71px;
                    display: block;
                    width: var(--arrow-size);
                    height: var(--arrow-size);
                    content: url('../../assets/img/animations/remax/bubble-arrow-square.svg');
                }

                &:has(.bubble[data-arrow='left']) {
                    &::before {
                        left: unset;
                        left: 62px;
                    }
                }
            }

            ol {
                counter-reset: item;
                list-style: none;

                li {
                    counter-increment: item;

                    &::before {
                        display: inline-grid;
                        width: 1.3em;
                        height: 1.3em;
                        padding-top: 0.2%;
                        border-radius: 50%;
                        margin-right: 0.5em;
                        background-color: var(--body-primary-color);
                        color: var(--color-white-text);
                        content: counter(item);
                        font-family: Maax;
                        line-height: 1.3em;
                        place-items: center;
                    }
                }
            }
        }

        .block-section {
            &[data-theme='outro'] {
                padding-top: 40px;
                padding-bottom: 30px;
                background:
                    linear-gradient(var(--bg-line-color) 1px, transparent 1px),
                    linear-gradient(90deg, var(--bg-line-color) 1px, transparent 1px);
                background-color: #1858b0;
                background-size: 20px 20px;
                color: var(--color-white-text);

                --bg-line-color: rgb(255 255 255 / 0.2);
            }
        }

        .block-table {
            & > div:first-child {
                width: 100%;
                padding: 15px 10px;
                border: 1.5px solid;
                border-color: currentcolor;
                border-radius: 12px;
                background-color: #e1e8f4;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;

                --block-table-font-family: 'Maax';

                thead {
                    border-bottom: 3px solid currentcolor;
                    font-size: 22px;

                    th {
                        padding: 10px;
                        padding-top: 0;
                    }
                }

                td {
                    padding: 14px 10px 11px;

                    /* numbers */
                    &:not(:first-child) {
                        text-align: right;
                        white-space: nowrap;
                    }
                }

                tbody {
                    tr {
                        border-bottom: 2px solid currentcolor;
                    }

                    td {
                        transition: color 0.3s ease-out, background-color 0.3s ease-out;
                    }

                    /* Derek and Olivia table */
                    &:has(tr > td:nth-child(3)) {
                        &::before {
                            display: block;
                            height: 10px;
                            content: '';
                        }

                        tr {
                            &:first-child {
                                font-family: 300;
                                font-size: 18px;
                                opacity: 0.7;

                                td {
                                    padding-top: 10px;
                                    text-align: center;
                                }
                            }

                            td:last-child {
                                border-left: 2px solid;
                            }
                        }
                    }

                    &::after {
                        display: block;
                        height: 15px;
                        content: '';
                    }
                }

                tfoot td {
                    background-color: var(--body-primary-color);
                    color: var(--color-white-text);
                    transition: color 0.3s ease-out, background-color 0.3s ease-out;

                    --border-radius: 50px;

                    &:first-child {
                        border-bottom-left-radius: var(--border-radius);
                        border-top-left-radius: var(--border-radius);
                    }

                    &:last-child {
                        border-bottom-right-radius: var(--border-radius);
                        border-top-right-radius: var(--border-radius);
                    }
                }

                tbody tr {
                    &:hover {
                        td {
                            color: #4356ab;
                        }
                    }
                }

                tfoot tr {
                    &:hover {
                        td {
                            background-color: #fff;
                            color: var(--body-primary-color);
                        }
                    }
                }
            }

            &[data-theme='red'] > div:first-child {
                background-color: #fde6e8;
                color: #f41c2c;

                tfoot td {
                    background-color: #f41c2c;
                    color: #fde6e8;
                }

                tfoot tr {
                    &:hover {
                        td {
                            background-color: #fff;
                            color: #f41c2c;
                        }
                    }
                }

                tbody tr {
                    &:hover {
                        td {
                            color: var(--body-primary-color);
                        }
                    }
                }
            }
        }

        .block-animation:has(.divider) {
            max-width: unset;
            padding: 0;
        }
    }
}

@keyframes salt-shake {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes star-spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
